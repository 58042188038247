import { server } from "../constants";


export const transactionService = {
    transaction
};

function transaction(chainId, hash) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chainId, hash })
    };
    return fetch(`${server}/api/transaction`, requestOptions)
        .then(handleResponse)
        .then(confirm => {
            return confirm;
        })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                console.error('server error')
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}