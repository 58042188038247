import React from "react";
import Countdown from "react-countdown";
import TimeValue from "./TimeValue";

const times = [
  {
    id: 523,
    name: "Days",
  },
  {
    id: 567,
    name: "Hours",
  },
  {
    id: 856,
    name: "Minutes",
  },
  {
    id: 123,
    name: "Seconds",
  },
];

interface rendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const renderer = (props: rendererProps) => {
  const {days, hours, minutes, seconds, completed} = props;

  const timeValues = [days, hours, minutes, seconds];
  const timesSetup = times.map((item, index) => ({
    ...item,
    time: timeValues[index],
  }));

  if (completed) {
    // Render a completed state
    return <></>;
  } else {
    // Render a countdown
    return (
      <>
        {timesSetup.map(({id, ...rest}) => (
          <TimeValue key={id} {...rest} />
        ))}
      </>
    );
  }
};

function TokenEnd() {
  return (
    <div className="bg-white bg-opacity-5 py-6 md:py-8 px-6 rounded-3xl backdrop-blur-[4px]">
      <h5 className="font-manrope text-4xl text-center mb-6">
        Token sale ends in :
      </h5>

      <div className="grid grid-cols-2 max-w-[300px] md:max-w-auto mx-auto md:flex md:justify-between gap-x-6 gap-y-8 md:gap-4">
        <Countdown date={Date.now() + 2000000000} renderer={renderer} />
      </div>
    </div>
  );
}

export default TokenEnd;
