
import { icoContract } from "config";
import { useBalance, useDecimals, useRate, useStable } from "hooks/useBalance";
import { useContract } from "hooks/useContract";
import React, { useEffect, useState } from "react";
import { getBalanceAmount, getBalanceNumber, getDecimalAmount } from "utils/formatBalance";
import { reduceWallet } from "utils/wallet";
import SelectField from "../../common/SelectField";
import ico_abi from 'abi/ico.json'
import stable_abi from 'abi/stable.json';
import { parseUnits } from "ethers/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { transactionService } from "service";
import useWallet from "contexts/WalletContext";



interface ModalProps {
  open: boolean;
  onClose: () => void;
}

function Modal({ onClose, open }: ModalProps) {

  const dispatch = useDispatch();
  const { ConnectWallet, connected, DisconnectWallet, account, chainId, provider, library } = useWallet();
  const [symbol, setSymbol] = useState("BUSD");
  const [kec, setKec] = useState(0);
  const [presale, setPresale] = useState(0);
  const [contract, setContract] = useState(icoContract[chainId]);
  const stable = useStable(symbol);
  const decimals = getBalanceAmount(useDecimals(stable), 0);
  const rate = getBalanceAmount(useRate(icoContract[chainId]), 0);
  const balance = getBalanceNumber(useBalance(stable), decimals);
  const [select, setSelect] = useState<number>(0);
  const ICOContract = useContract(contract, ico_abi, library)
  const StableContract = useContract(stable, stable_abi, library);

  useEffect(() => {
    if (open) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [open]);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, [])

  useEffect(() => {
    if (chainId || chainId != undefined) {
      setContract(icoContract[chainId])
    }
  }, [chainId])

  useEffect(() => {
    if (select == 0) {
      setSymbol("BUSD");
    } else {
      setSymbol("USDT");
    }
  }, [select])

  const setKECAmount = (value) => {
    if (!isNaN(Number(value))) {
      setPresale(value);
      setKec(value * 1000 / rate);
    }

  }

  const showNotification = (message) => {
    new Notification(message)
  }
  const BuyKEC = async () => {

    if (presale <= 0) {
      return;
    }



    dispatch({ type: "CHANGE_LOAD", payload: true })
    try {
      let amount = parseUnits(presale.toString(), decimals);

      let approve = await StableContract.approve(contract, amount);
      await approve.wait();
      if (approve.hash) {
        let result = await ICOContract.DepositTokens(stable, amount);
        await result.wait();
        if (result.hash) {
          let res = await transactionService.transaction(chainId, result.hash)
          if (res.status == 'success') {
            showNotification("Please confirm the wallet");
          } else if (res.status == 'transaction error') {
            showNotification("There is some issues in transaction! \n Please check the Transaction hash");
          }
          else {
            showNotification("There is some issues in server! \n Please contact the admin!")
          }
        }
        // console.log(result);
        // let test = web3.eth.getTransaction(result.hash);
        // console.log(test);
      }
    } catch(error) {
      dispatch({ type: "CHANGE_LOAD", payload: false })
      console.log(error)
    }


    dispatch({ type: "CHANGE_LOAD", payload: false })
  }





  return open ? (
    <div className="">

      <div className="fixed z-[1] top-0 bottom-0 right-0 left-0 flex items-center justify-center">
        <div
          onClick={onClose}
          className="fixed z-0 bg-opacity-90 bg-black top-0 right-0 left-0 bottom-0 cursor-pointer"
        />
        <div className="max-w-[450px] w-full mx-auto p-4 rounded-3xl z-10 relative backdrop-blur-lg bg-[#1A1A1A] text-gray-700">
          <div style={{"marginTop" : "20px"}}>
            <h4 className="text-center uppercase font-bold text-xl mb-4 text-white">
              buy kec
            </h4>
            {
              account && <h5 className="text-center uppercase font-bold text-xl mb-4 text-white">{reduceWallet(account)}</h5>

            }
          </div>
          {/* <div className="flex flex-col gap-5 mb-5">
            <div className="pt-3 pb-7 px-6 rounded-2xl bg-[#333333] text-white text-opacity-50">
              <p className="text-sm block mb-2 text-white">Network</p>

              <div className="flex items-center" style={{"justifyContent" : "center"}}>
                <SelectField

                  options={[
                    {
                      id: 4534,
                      text: "BNB Network",
                      icon: "/images/bnb.png",
                    },
                    {
                      id: 8565,
                      text: "ETH Network",
                      icon: "/images/eth.png",
                    }
                  ]} setSelect={setSelect}
                />
              </div>
            </div>
            
          </div> */}
          <div className="flex flex-col gap-5 mb-5">
            <div className="pt-3 pb-7 px-6 rounded-2xl bg-[#333333] text-white text-opacity-50">
              <p className="text-sm block mb-2 text-white">Balance : {balance} {symbol}</p>

              <div className="flex items-center">
                <SelectField

                  options={[
                    {
                      id: 4534,
                      text: "BUSD",
                      icon: "/images/binance-usd-busd-logo.svg",
                    },
                    {
                      id: 8565,
                      text: "USDT",
                      icon: "/images/tether-usdt-logo.svg",
                    }
                  ]} setSelect={setSelect}
                />
                <span className="w-[1px] h-7 text-white bg-opacity-50 mx-4" />
                {
                  account ?
                    (
                      <input
                        type="text" value={presale}
                        onChange={(e) => setKECAmount(e.target.value)}
                        placeholder="Enter here..."
                        className="placeholder:text-white placeholder:text-opacity-70 text-white"
                      />
                    ) :
                    (
                      <input
                        type="text" value={presale}
                        disabled
                        placeholder="Enter here..."
                        className="placeholder:text-white placeholder:text-opacity-70 text-white"
                      />
                    )
                }

              </div>
            </div>
            <div className="flex justify-center">
              <img src="/images/buton.png" alt="" />
            </div>
            <div className="pt-3 pb-7 px-6 rounded-2xl bg-[#333333] text-white text-opacity-50">
              <p className="text-sm block mb-2 text-white">Rate : {rate}</p>
              <div className="flex items-center">
                <SelectField
                  options={[
                    {
                      id: 5765,
                      text: "KEC",
                      icon: "/images/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R.png",
                    },
                  ]}
                />
                <span className="w-[1px] h-7 bg-brand-100 bg-opacity-50 mx-4" />
                <p className="text-white text-opacity-90">{kec}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {
              connected ?
                <div className="grid justify-center">
                  <button className="gap-5 mb-5 font-bold text-xl px-7 py-4 bg-primary-700 text-gray-1000 hover:bg-white contained rounded-full" onClick={() => BuyKEC()}>Buy the KEC</button>
                  <button className="gap-5 mb-5 font-bold text-xl px-7 py-4 bg-primary-700 text-gray-1000 hover:bg-white contained rounded-full" onClick={async() => await DisconnectWallet()} style={{"background" : "white"}}>Disconnect Wallet</button>

                </div>

                :

                <button className="font-bold text-xl px-7 py-4 bg-primary-700 text-gray-1000 hover:bg-white contained rounded-full" onClick={async () => await ConnectWallet()}>Connect Wallet</button>
            }
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Modal;
