
import {useMemo} from "react";
//import { getMarketplaceContract, getBep20Contract } from '../utils/contractHelper';
import {getContract} from "../utils/contract";
import { ethers } from "ethers";
import useWallet from "contexts/WalletContext";


export const useContract = (
  address: string,
  ABI: any,
  withSignerIfPossible = true
) => {
  const {library, account} = useWallet();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;

    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : ""
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
};
