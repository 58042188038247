import React from 'react'
import { Provider } from 'react-redux'
import { RefreshContextProvider } from './contexts/RefreshContext'
import { configureStore } from './state';
import { WalletProvider } from 'contexts/WalletContext';

const store = configureStore(undefined);

const Providers = ({ children }) => {
    return (
        <Provider store={store}>
            <WalletProvider>
                <RefreshContextProvider>
                    {children}
                </RefreshContextProvider>
            </WalletProvider>
        </Provider>
    )

}

export default Providers;