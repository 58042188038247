import React, { useState, useEffect, useMemo, useCallback } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { tips } from "utils/wallet";
import { ethers } from 'ethers'



export const WalletContext = React.createContext(null);

export const WalletProvider = ({ children }) => {

    const [connected, setConnected] = useState(false);
    const [account, setAccount] = useState("");
    const [chainId, setChainId] = useState(1);
    const [provider, setProvider] = useState(null);
    const [library, setLibrary] = useState(null);
    const [web3Modal, setWeb3Modal] = useState(null);

    const ConnectWallet = async () => {
        try {
            const providerOptions = {
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        infuraId: "e5c6c650aa80469083146d10d00a0732",
                        bridge: 'https://bridge.walletconnect.org/',
                        qrcode: true,
                    },
                },
            };

            const web3Modal = new Web3Modal({
                network: "mainnet", // optional
                cacheProvider: true, // optional
                providerOptions, // required
                // theme: {
                //     background: "rgb(11 11 72 / 92%)",
                //     main: "rgb(199, 199, 199)",
                //     secondary: "rgb(136, 136, 136)",
                //     border: "rgba(195, 195, 195, 0.14)",
                //     hover: "rgb(16, 26, 32)"
                // }
            });
            const provider = await web3Modal.connect();
            const library = new ethers.providers.Web3Provider(provider);
            const network = await library.getNetwork();
            //await subscribeProvider(provider);
            const accounts = await library.listAccounts();
            const address = accounts[0];
            const chainId = parseInt(network.chainId.toString(), 10);
            if(chainId != 56 && chainId != 1){
                tips("Please set the correct network!")
                return;
            }
            setProvider(provider);
            setLibrary(library);
            setConnected(true);
            setAccount(address);
            setChainId(chainId);
            setWeb3Modal(web3Modal);

        } catch (error) {
            console.log(error);
        }
    };


    const DisconnectWallet = async () => {
        try {
            await web3Modal.clearCachedProvider();
            setWeb3Modal(null);
            await setChainId(1);
            await setAccount("");
            await setConnected(false);
        } catch (error) {
            console.log(error);
        }
        // this.setState({ ...INITIAL_STATE });
    };

    useEffect(() => {
        if (provider?.on) {
          const handleAccountsChanged = (accounts) => {
            console.log("accountsChanged", accounts);
            if (accounts) setAccount(accounts[0]);
          };
    
          const handleChainChanged = (_hexChainId) => {
            setChainId(_hexChainId);
          };
    
          const handleDisconnect = () => {
            DisconnectWallet();
          };
    
          provider.on("accountsChanged", handleAccountsChanged);
          provider.on("chainChanged", handleChainChanged);
          provider.on("disconnect", handleDisconnect);
    
          return () => {
            if (provider.removeListener) {
              provider.removeListener("accountsChanged", handleAccountsChanged);
              provider.removeListener("chainChanged", handleChainChanged);
              provider.removeListener("disconnect", handleDisconnect);
            }
          };
        }
      }, [provider]);




    const values = {
        setConnected,
        ConnectWallet,
        DisconnectWallet,
        provider,
        library,
        account,
        connected,
        chainId
    };

    return (
        <WalletContext.Provider value={values}>
            {children}
        </WalletContext.Provider>
    );
};



export default function useWallet() {
    const context = React.useContext(WalletContext);

    if (context === undefined) {
        throw new Error(
            "useMetaMask hook must be used with a MetaMaskProvider component"
        );
    }

    return context;
}
